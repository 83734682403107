<script>
import CommonLayout from "@/views/CommonLayout.vue";

export default {
  name: "About",
  components: {
    CommonLayout
  },
  beforeMount() {
    window.scrollTo({ top: 0, left: 0 });
  }
};
</script>

<template>
  <CommonLayout>
    <div class="content">
      <div class="about-content">
        <div class="heading-container animate__animated animate__fadeInDown">
          <div class="about-column">
            <h1 class="page-title">{{ $t("about.aboutTitle") }}</h1>
          </div>
        </div>

        <div class="row">
          <div class="about-column animate__animated animate__fadeIn animate__slow">
            <p class="paragraph">
              {{ $t("about.paragraph1") }}
            </p>

            <p class="paragraph">
              {{ $t("about.paragraph2") }}
            </p>

            <p class="paragraph">
              {{ $t("about.paragraph3") }}
            </p>

            <p class="paragraph">
              {{ $t("about.paragraph4") }}
            </p>

            <p class="paragraph">
              {{ $t("about.paragraph5") }}
            </p>

            <p class="paragraph">
              {{ $t("about.paragraph6") }}
            </p>
          </div>

          <!--div column used to create space in between two main columns-->
          <div class="space-column"></div>

          <div class="about-column">
            <img class="rockImg" src="@/assets/sri-lanka-illustration.webp" alt="Sigiriya Rock" />
          </div>
        </div>
      </div>
    </div>
  </CommonLayout>
</template>

<style scoped lang="scss">
@import "/src/styles/views/about.scss";
</style>
